#wrapper {
  font-family: auto;
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
  color: #333;
  margin: 1.5%
}

#wrapper #dialog {
  border: solid 1px #ccc;
  margin: 10px auto;
  padding: 20px 30px;
  display: inline-block;
  box-shadow: 0 0 4px #ccc;
  background-color: #faf8f8;
  overflow: hidden;
  position: relative;
  max-width: 900px;
  border-radius: 3px;
}

#wrapper #dialog #header {
  margin: 0 0 14px;
  padding: 0;
  line-height: 1.25;
  font-size: 1.5rem;
  text-align: left;
}

#wrapper #dialog #body {
  margin: 0 0 14px;
  padding: 0;
  line-height: 1.25;
  font-size: 1rem;
  text-align: left;
}

@media screen and (max-width: 600px) {
  #wrapper #dialog #body {
    font-size: 0.8rem;
  }
}

#wrapper #dialog #form input {
  text-align: left;
  line-height: 35px;
  font-size: 30px;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  width: 99%;
  transition: all 0.2s ease-in-out;
  border-radius: 1px;
}

#wrapper #dialog #sendsms {
  padding: 0px;
  margin-top: 0.5rem;
  text-align: left;
}

@media screen and (max-width: 600px) {
  #wrapper #dialog #sendsms {
    font-size: 0.9rem;
  }
}

#wrapper #dialog #form input::selection {
  background: transparent;
}

#wrapper #dialog a {
  color: #0b5ed7;
}

#wrapper #dialog #button {
  padding: 0px;
  margin: 0px;
  text-align: right;
}

#wrapper #dialog #button button {
  background-color: #005a9a;
}

#wrapper #dialog #button button:hover {
  background: #0b5ed7;
}

#wrapper #dialog button.close {
  border: solid 2px;
  border-radius: 30px;
  line-height: 19px;
  font-size: 120%;
  width: 22px;
  position: absolute;
  right: 5px;
  top: 5px;
}

#wrapper #dialog #logo {
  margin: 0rem 0rem 1rem 0rem;
  padding: 0px;
  width: 20rem;
}

#wrapper #dialog #validationError {
  color: red;
}
