.container {
  max-width: 100%;
}

.consent-confirmation-invalid__wrapper {
  box-shadow: 3px 3px 5px 3px #f1f1f1;
  border-radius: 10px;
  background-color: #faf8f8;
  padding: 1%;
  text-align: center;
  margin: auto;
  width: 80%;
  margin-top: 5rem;
}

@media screen and (min-width: 600px) {
  .consent-confirmation-invalid__wrapper {
    width: 35%;
  } 
}

.consent-confirmation-invalid__confirmed-image {
  margin-top: 10%;
  margin-bottom: 5%;
  max-width: 100%;
}

@media screen and (min-width: 600px) {
  .consent-confirmation-invalid__confirmed-image {
    margin-bottom: 15%;
    margin-left: 5%;
  }
}

.consent-confirmation-invalid__confirmed-image-text {
  font-size: 0.52em;
  text-align: left;
}

.consent-confirmation-invalid__align-text-left {
  text-align: left;
  padding: 3%;
}

@media screen and (min-width: 600px) {
  .consent-confirmation-invalid__align-text-left {
    margin-top: 20%;
    margin-bottom: 15%;
  }
}

.consent-confirmation-invalid-logo {
  margin: 1rem 0rem 0rem 0.5rem;
  width: 26rem;
  transition: ease all 0.5s;
}

@media screen and (max-width: 1500px) {
  .consent-confirmation-invalid-logo {
    width: 22rem;
  }
}

@media screen and (max-width: 1200px) {
  .consent-confirmation-invalid-logo {
    width: 16.5rem;
  }
}

@media screen and (max-width: 860px) {
  .consent-confirmation-invalid-logo {
    width: 14rem;
  }
}

@media screen and (max-width: 740px){
  .consent-confirmation-invalid-logo {
    width: 100%;
  }
}

@media screen and (max-width: 599px){
  .consent-confirmation-invalid-logo {
    margin: 1rem 0rem 0rem 0rem;
    width: 100%;
  }
}
