.ReactModal__Overlay {
  opacity: 0;


  position: fixed;
  top: 0;
  left: 0;
  inset: 0px;

  transition: opacity 500ms ease-in-out;

  
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal__close {
  float: right;
  font-size: 25px;
  font-weight: 700;
  line-height: 0;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.3;
}

.modal__close-button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.modal__close-button:hover {
  color: gray;
}
